import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import React, { useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import HeroImage from '../components/HeroImage';
import Layout from '../components/Layout';
import MaskText from '../components/MaskText';
import { createTag } from '../components/Tag';
import TagList from '../components/TagList';
import { SMALL_VIEW_PORT } from '../constants/style';
import { MarkdownRemark } from '../types/query';
import styled from '../utils/styled-components';

type PostNode = {
  html: string;
  frontmatter: {
    title: string;
    color: string;
    tags: string[];
    image: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
};

type QueryData = MarkdownRemark<PostNode>;

const PostTemplate: React.FCWithQuery<QueryData> = ({ data }) => {
  const { markdownRemark } = data;
  const style = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: {
      opacity: 0,
      transform: 'translateY(20px)'
    }
  });
  const tags = useMemo(
    () => markdownRemark.frontmatter.tags.map(tag => createTag(tag)),
    [markdownRemark.frontmatter.tags]
  );
  return (
    <Layout
      color={markdownRemark.frontmatter.color}
      title={markdownRemark.frontmatter.title}
    >
      <animated.div style={style}>
        <Article>
          <Header>
            <MaskText maskColor={markdownRemark.frontmatter.color}>
              <Title>{markdownRemark.frontmatter.title}</Title>
            </MaskText>
          </Header>
          <Hero>
            <HeroImage
              backgroundColor={markdownRemark.frontmatter.color}
              fluid={markdownRemark.frontmatter.image.childImageSharp.fluid}
            />
          </Hero>
          <Content dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
          <TagListWrapper>
            <TagList items={tags} />
          </TagListWrapper>
        </Article>
      </animated.div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        color
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;

const Article = styled.article`
  position: relative;
  margin: 0;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    margin: 0 1rem;
  }
`;

const Header = styled.header`
  padding: 2rem 0;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    padding: 4rem 0;
  }
`;

const Title = styled.h1`
  display: inline-block;
  margin: 0;
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  font-weight: 100;
  overflow: hidden;
`;

const Hero = styled.section`
  position: relative;
  padding: 1.5rem 1rem;
  background-color: #fff;
`;

const Content = styled.div`
  margin: 1.5rem 0 0;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 100;

  > h3 {
    margin: 0 0 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
  }

  > p {
    margin-bottom: 1rem;

    > a {
      color: currentColor;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const TagListWrapper = styled.div`
  margin: 0 0 1.5rem;
  padding: 0;

  > h3 {
    margin: 0 0 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
  }
`;

export default PostTemplate;
