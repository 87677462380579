import Img, { FluidObject } from 'gatsby-image';
import React, { useCallback } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from '../../utils/styled-components';

type Props = {
  backgroundColor: string;
  fluid: FluidObject;
};

const defaultXY = [0, 0];
const calcRotate = (x: number, y: number) => [
  -(y - window.innerHeight / 2) / 80,
  (x - window.innerWidth / 2) / 80
];
const trans = (x: number, y: number) => {
  return `perspective(800px) rotateX(${x}deg) rotateY(${y}deg)`;
};

const HeroImage: React.FC<Props> = props => {
  const { backgroundColor, fluid } = props;
  const [style, setStyle] = useSpring(() => ({
    xy: defaultXY,
    config: { mass: 5, tension: 350, friction: 40 }
  }));
  const handleMouseMove = useCallback(
    ({ clientX: x, clientY: y }: React.MouseEvent) => {
      setStyle({ xy: calcRotate(x, y) });
    },
    []
  );
  const handleMouseLeave = useCallback(() => {
    setStyle({ xy: defaultXY });
  }, []);
  return (
    <Wrapper>
      <animated.div
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          // interpolateの型定義が合わない
          transform: style.xy.interpolate(trans as any)
        }}
      >
        <Image backgroundColor={backgroundColor} fluid={fluid} />
      </animated.div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Image = styled(Img)`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
`;

export default HeroImage;
